var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full md:w-1/2 lg:w-3/4" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-start" },
          _vm._l(_vm.widgetCategories, function (category, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass:
                  "mr-2 mt-2 vs-component vs-button vs-button-primary",
                class: {
                  "vs-button-filled": _vm.activeCategoryId === category.id,
                  "vs-button-border": _vm.activeCategoryId !== category.id,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.filterCategory(category.id)
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "vs-button-backgroundx vs-button--background",
                  staticStyle: {
                    opacity: "1",
                    left: "20px",
                    top: "20px",
                    width: "0px",
                    height: "0px",
                    transition:
                      "width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s",
                  },
                }),
                _c("span", { staticClass: "vs-button-text vs-button--text" }, [
                  _vm._v(_vm._s(category.name)),
                ]),
                _c("span", {
                  staticClass: "vs-button-linex",
                  staticStyle: {
                    top: "auto",
                    bottom: "-2px",
                    left: "50%",
                    transform: "translate(-50%)",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 lg:pl-0 mb-4" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-2",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-search",
              placeholder: "Search",
            },
            on: {
              input: function ($event) {
                _vm.activeCategoryId = 0
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "vx-row mt-4 match-height" },
      _vm._l(_vm.ourWidgetList, function (ourWidget, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-4" },
          [
            _c("application-widget-card", {
              attrs: { widget: ourWidget, applicationId: _vm.applicationId },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "app-heading mb-4" }, [
        _vm._v("Widget Directory"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }